// useAppInsights.js
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

import { GenericType } from "../types";

const INSTRUMENTATION_KEY = "323b04f5-4f73-46cc-8d9b-53efd23c3ccd"; // Replace with your Application Insights Instrumentation Key

let appInsights: GenericType | null = null;

// const ENABLE_APP_INSIGHTS = process.env.NODE_ENV === "production";
const ENABLE_APP_INSIGHTS = true;

const initializeAppInsights = () => {
  if (!appInsights && ENABLE_APP_INSIGHTS) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: INSTRUMENTATION_KEY,
        enableAutoRouteTracking: true,
        disableExceptionTracking: true,
      },
    });
    appInsights.loadAppInsights();
  }
};

const updateTelemetryInitializer = (user: GenericType | null) => {
  const companyName = user?.profile?.company;
  const id = user?.id;
  if (appInsights && user) {
    appInsights.addTelemetryInitializer((envelope: GenericType) => {
      if (user && companyName) {
        envelope.data = envelope.data || {};

        // Add companyName and userid to properties
        envelope.data.companyName = companyName;
        envelope.data.userId = id;
      }
    });
  }
};

const useAppInsights = () => {
  const logAction = (actionName: GenericType, properties: GenericType) => {
    if (ENABLE_APP_INSIGHTS && appInsights) {
      appInsights.trackEvent({ name: actionName, properties });
    }
  };
  const logError = (error: GenericType, properties: GenericType) => {
    if (ENABLE_APP_INSIGHTS && appInsights) {
      appInsights.trackException({ exception: error, properties: properties });
    }
  };

  const logTrace = (message: GenericType, properties: GenericType) => {
    if (ENABLE_APP_INSIGHTS && appInsights) {
      appInsights.trackTrace({ message, properties });
    }
  };

  const logMetric = (
    name: GenericType,
    average: GenericType,
    properties: GenericType,
  ) => {
    if (ENABLE_APP_INSIGHTS && appInsights) {
      appInsights.trackMetric({ name, average, properties });
    }
  };

  return {
    logAction,
    logError,
    logTrace,
    logMetric,
  };
};
export { initializeAppInsights, updateTelemetryInitializer };
export default useAppInsights;
